<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div
      class="modal fade"
      id="tep_viajes_export"
      style="background-color: #00000082"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">
              Generar listado Sett
            </h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="tab-Columnas"
                  data-toggle="tab"
                  href="#Columnas"
                  >Columnas</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="tab-Filtros"
                  data-toggle="tab"
                  href="#Filtros"
                  >Filtros
                </a>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane" id="Filtros">
                <div class="card-body">
                  <div class="form-group row">
                    <label for="fecha_ini" class="col-md-5"
                      >Fecha Inicial</label
                    >
                    <input
                      type="date"
                      id="fecha_ini"
                      v-model="filtros.fecha_ini"
                      class="col-md-7"
                      @change="validaFechas()"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="fecha_fin" class="col-md-5">Fecha Final</label>
                    <input
                      type="date"
                      id="fecha_fin"
                      v-model="filtros.fecha_fin"
                      class="col-md-7"
                      @change="validaFechas()"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="empresa_id" class="col-md-5">Empresa</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="empresa"
                      placeholder="Empresa"
                      label="razon_social"
                      class="form-control form-control-sm p-0 col-md-7"
                      :options="listasForms.empresas"
                      :filterable="true"
                      @input="getSelectEmpresa()"
                    ></v-select>
                  </div>
                  <div class="form-group row">
                    <label for="vehiculo_id" class="col-md-5">Vehículo</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="vehiculo"
                      placeholder="Vehículo"
                      label="placa"
                      class="form-control form-control-sm p-0 col-md-7"
                      :options="listasForms.vehiculos"
                      :filterable="true"
                      @input="getSelectVehiculo()"
                    ></v-select>
                  </div>
                  <div class="form-group row">
                    <label for="conductor_id" class="col-md-5">Conductor</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="conductor"
                      placeholder="Conductor"
                      label="nombre"
                      class="form-control form-control-sm p-0 col-md-7"
                      :options="listasForms.conductores"
                      :filterable="true"
                      @input="getSelectConductor()"
                    ></v-select>
                  </div>
                  <div class="form-group row">
                    <label for="ruta_id" class="col-md-5">Ruta</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="ruta"
                      placeholder="Ruta"
                      label="nombre"
                      class="form-control form-control-sm p-0 col-md-7"
                      :options="listasForms.rutas"
                      :filterable="true"
                      @input="getSelectRuta()"
                    ></v-select>
                  </div>
                  <div class="form-group row">
                    <label for="tipo" class="col-md-5">Tipo Vehículo</label>
                    <select
                      id="tipo_vehiculo"
                      class="form-control form-control-sm col-md-7"
                      v-model="filtros.tipo_vehiculo"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="tipo_vehiculo in listasForms.tipos_vehiculos"
                        :key="tipo_vehiculo.numeracion"
                        :value="tipo_vehiculo.numeracion"
                      >
                        {{ tipo_vehiculo.descripcion }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group row">
                    <label for="estado1" class="col-md-5"> Estado</label>
                    <select
                      id="estado"
                      class="form-control form-control-sm col-md-7"
                      v-model="filtros.estado"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="estado in listasForms.estados"
                        :key="estado.numeracion"
                        :value="estado.numeracion"
                      >
                        {{ estado.descripcion }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade active show" id="Columnas">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-11">
                      <div class="form-group">
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="viaje_id"
                              v-model="form.viaje_id"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="viaje_id"
                              ># Viaje</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="solicitud_id"
                              v-model="form.solicitud_id"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="solicitud_id"
                              ># Solicitud</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="conductor_id"
                              v-model="form.conductor_id"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="conductor_id"
                              >Conductor</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="vehiculo_id"
                              v-model="form.vehiculo_id"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="vehiculo_id"
                              >Vehículo</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="tipo_vehiculo_id"
                              v-model="form.tipo_vehiculo_id"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="tipo_vehiculo_id"
                              >Tipo Vehículo</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="tep_turno_id"
                              v-model="form.tep_turno_id"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="tep_turno_id"
                              >Turno</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="fecha_ini1"
                              v-model="form.fecha_ini"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="fecha_ini1"
                              >Fecha Inicial</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="fecha_fin1"
                              v-model="form.fecha_fin"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="fecha_fin1"
                              >Fecha Final</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="odometro_inicial"
                              v-model="form.odometro_inicial"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="odometro_inicial"
                              >Odómetro Inicial</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="odometro_fin"
                              v-model="form.odometro_fin"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="odometro_fin"
                              >Odómetro Final</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="direccion_origen"
                              v-model="form.direccion_origen"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="direccion_origen"
                              >Origen</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="direccion_destino"
                              v-model="form.direccion_destino"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="direccion_destino"
                              >Destino</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="lat_long_llegada"
                              v-model="form.lat_long_llegada"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="lat_long_llegada"
                              >Lat-Lon Llegada</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="lat_long_ini_user"
                              v-model="form.lat_long_ini_user"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="lat_long_ini_user"
                              >Lat-Long-Ini Usuario</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="lat_long_fin_user"
                              v-model="form.lat_long_fin_user"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="lat_long_fin_user"
                              >Lat-Long-Fin Usuario</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="lat_long_ini_conductor"
                              v-model="form.lat_long_ini_conductor"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="lat_long_ini_conductor"
                              >Lat-Long-Ini Conductor</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="lat_long_fin_conductor"
                              v-model="form.lat_long_fin_conductor"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="lat_long_fin_conductor"
                              >Lat-Long-Fin Conductor</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="tep_ruta_id"
                              v-model="form.tep_ruta_id"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="tep_ruta_id"
                              >Ruta</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="empresa_id"
                              v-model="form.empresa_id"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="empresa_id"
                              >Empresa</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="tipo_inicio"
                              v-model="form.tipo_inicio"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="tipo_inicio"
                              >Tipo Inicio</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="tipo_fin"
                              v-model="form.tipo_fin"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="tipo_fin"
                              >Tipo Fin</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="user_id"
                              v-model="form.user_id"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="user_id"
                              >Usuario</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="razon_cancelacion"
                              v-model="form.razon_cancelacion"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="razon_cancelacion"
                              >Razón Cancelación</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="estado1"
                              v-model="form.estado"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="estado1"
                              >Estado</label
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer justify-content-between">
            <div>
              <button
                type="button"
                class="btn btn-sm bg-navy"
                @click="generarListadoExcel()"
                v-if="$store.getters.can('tep.viajes.export')"
              >
                Generar Listado
                <i class="fas fa-file-download"> </i>
              </button>
            </div>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import Loading from "../../../../components/Loading";
export default {
  name: "TepViajeExport",
  components: {
    Loading,
    vSelect,
  },
  data() {
    return {
      cargando: false,
      empresa: {},
      ruta: {},
      conductor: {},
      vehiculo: {},
      form: {
        viaje_id: true,
        solicitud_id: true,
        conductor_id: true,
        vehiculo_id: true,
        tipo_vehiculo_id: true,
        tep_turno_id: true,
        fecha_ini: true,
        fecha_fin: true,
        odometro_inicial: true,
        odometro_fin: true,
        direccion_origen: true,
        direccion_destino: true,
        lat_long_llegada: true,
        lat_long_ini_user: true,
        lat_long_fin_user: true,
        lat_long_ini_conductor: true,
        lat_long_fin_conductor: true,
        tep_ruta_id: true,
        empresa_id: true,
        tipo_inicio: true,
        tipo_fin: true,
        user_id: true,
        razon_cancelacion: true,
        estado: true,
      },
      filtros: {
        fecha_ini: null,
        fecha_fin: null,
        empresa_id: null,
        empresa: null,
        ruta_id: null,
        ruta: null,
        conductor_id: null,
        conductor: null,
        vehiculo_id: null,
        vehiculo: null,
        tipo_vehiculo: null,
        estado: null,
      },
      listasForms: {
        vehiculos: [],
        conductores: [],
        empresas: [],
        rutas: [],
        tipos_vehiculos: [],
        estados: [],
      },
    };
  },

  methods: {
    async getEmpresa() {
      let me = this;
      me.listasForms.empresas = [];
      var url = "api/admin/empresas/lista?estado=1";
      await axios
        .get(url, {
          params: {
            linea_negocio_id: [5],
          },
        })
        .then(function(response) {
          me.listasForms.empresas = response.data;
        })
        .catch(function(e) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async getVehiculo() {
      let me = this;
      me.listasForms.vehiculos = [];
      var url = "api/admin/vehiculos/lista?estado=1";
      await axios
        .get(url, {
          params: {
            linea_negocio_id: [5],
          },
        })
        .then(function(response) {
          me.listasForms.vehiculos = response.data;
        })
        .catch(function(e) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async getConductor() {
      let me = this;
      me.listasForms.conductores = [];
      var url = "api/admin/conductores/lista?estado=1";
      await axios
        .get(url, {
          params: {
            linea_negocio_id: [5],
          },
        })
        .then(function(response) {
          me.listasForms.conductores = response.data;
        })
        .catch(function(e) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async getRuta() {
      let me = this;
      me.listasForms.rutas = [];
      var url = "api/tep/Ruta/lista?estado=1";
      await axios
        .get(url, {
          params: {},
        })
        .then(function(response) {
          me.listasForms.rutas = response.data;
        })
        .catch(function(e) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    getSelectVehiculo() {
      this.filtros.vehiculo = {};
      this.filtros.vehiculo_id = null;
      if (this.vehiculo) {
        this.filtros.vehiculo = this.vehiculo;
        this.filtros.vehiculo_id = this.vehiculo.id;
      }
    },

    getSelectConductor() {
      this.filtros.conductor = {};
      this.filtros.conductor_id = null;
      if (this.conductor) {
        this.filtros.conductor = this.conductor;
        this.filtros.conductor_id = this.conductor.id;
      }
    },

    getSelectRuta() {
      this.filtros.ruta = {};
      this.filtros.ruta_id = null;
      if (this.ruta) {
        this.filtros.ruta = this.ruta;
        this.filtros.ruta_id = this.ruta.id;
      }
    },

    getSelectEmpresa() {
      this.filtros.empresa = {};
      this.filtros.empresa_id = null;
      if (this.empresa) {
        this.filtros.empresa = this.empresa;
        this.filtros.empresa_id = this.empresa.id;
      }
    },

    getEstados() {
      axios.get("/api/lista/109").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    validaFechas() {
      const fecha_menor = new Date(this.filtros.fecha_ini);
      const fecha_mayor = new Date(this.filtros.fecha_fin);
      // Se valida que la fecha inicial sea menor que la fecha final
      if (fecha_menor > fecha_mayor) {
        this.filtros.fecha_fin = null;
        this.$swal({
          icon: "error",
          title: `La fecha inicial no puede ser mayor a la fecha final...`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    },

    generarListadoExcel() {
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/tep/viajes/export",
        data: { form: this.form, filtros: this.filtros },
      })
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          location.href = data.url;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },
  },

  async mounted() {
    this.cargando = true;
    await this.getEmpresa();
    await this.getVehiculo();
    await this.getConductor();
    await this.getRuta();
    await this.getEstados();
    this.cargando = false;
  },
};
</script>
